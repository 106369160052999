@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');


*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  scroll-behavior: smooth;
}
html{
  scroll-behavior: smooth;
  background-color: black;
}
body{
  scroll-behavior: smooth;
}

.scene{
  height: 100vh;
}


#container {
	height: 100vh;
	width: 300%;
	display: flex;
	flex-wrap: nowrap;
  /* color: white; */
}

.horizontal-section {
	height: 100vh;
	width: 100vw;
	display: flex;
	justify-content:space-around;
	align-items: center;
  font-weight: 600;
  scroll-behavior: smooth;
}
.bgContent{
  color: beige;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  object-fit: cover;
}

.bgContent::after{
  content: "";
  position: absolute;
  top: 0;
  z-index: 1;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;  
  background-color: rgba(0,0,0,0.8); 
}

.bgContent h2{
  font-size: 6rem;
  z-index: 10;
}

.bgContent2{
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  text-align: left;
  padding-left: 5rem;
}
.bgContent2 h2 {
  font-size: 6rem;
  /* padding-left: 5rem; */
  font-weight: 500;
  font-style: italic;
}


.side{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 4rem;
}

.special{
  align-content: center;
  align-self: center;
  text-align: center;
  font-size: 6rem /* 60px */;
  line-height: 1.2;
}
.fade{
  opacity: 0.5;
  -webkit-text-stroke: 1px white;
  color: transparent;
}
.name{
  font-size: 8rem;
}

.marquee{
  font-size: 6rem;
  line-height: 2;
}

.loader {
  --s: 20px;
  
  --_d: calc(0.353*var(--s));
  width: calc(var(--s) + var(--_d));
  aspect-ratio: 1;
  display: grid;
}
.loader:before,
.loader:after {
  content: "";
  grid-area: 1/1;
  clip-path: polygon(var(--_d) 0,100% 0,100% calc(100% - var(--_d)),calc(100% - var(--_d)) 100%,0 100%,0 var(--_d));
  background:
    conic-gradient(from -90deg at calc(100% - var(--_d)) var(--_d),
     #fff 135deg,#666 0 270deg,#aaa 0);
  animation: l6 2s infinite;
}
.loader:after {
  animation-delay:-1s;
}
@keyframes l6{
  0%  {transform:translate(0,0)}
  25% {transform:translate(30px,0)}
  50% {transform:translate(30px,30px)}
  75% {transform:translate(0,30px)}
  100%{transform:translate(0,0)}
}



@media (max-width: 640px){
  .horizontal-section {
    /* background-color: azure; */
    flex-direction: column;
    justify-content: space-evenly;
    align-self: center;
    align-items: center;
    text-align: center;
    padding: 2%;
  }

  .horizontal-section h2 {
    margin-top: -5rem;
    font-size: 2.8rem;
    line-height: 1; 
  }

  .special {
    font-size: 3rem;
    line-height: 1.1; 
    text-align: center;
    align-items: center;
    /* color: darkgrey; */
  }
  .fade{
    opacity: 0.5;
  }
  .side{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 1rem;
    text-align: center;
    font-size: 2.5rem;
    line-height:1;
    margin-top: 0;
  }
  .side2{
    flex-direction: column-reverse;
  }

  .bgContent h2{
    font-size: 2rem;
  }
  .bgContent2{
    padding-left: 2rem;
  }
  .bgContent2 h2{
    font-size: 1.8rem;
    padding-left: 0;
  }
  .marquee{
    font-size: 3rem;
  }
  
  
}
